import React, { useState, useEffect, useMemo } from 'react'
import Table from '../Components/Table';
import { useDispatch, useSelector } from 'react-redux'
import { fetchUsers, showPopUp, showUserDetails, approveUser } from '../redux/reducers/users'
import UserCard from '../Components/UserCard';
import { fetchdssd } from '../redux/reducers/joining';
import ApproveModal from '../Components/ApprovaModal';


const Users = () => {

    const dispatch = useDispatch()

    const { loading, error, usersData, selectedUser, popUp } = useSelector(state => state.users)

    const { districts, states, departments, sessions } = useSelector(state => state.joining)

    useEffect(() => {
        dispatch(fetchUsers())
        dispatch(fetchdssd())
    }, [])

    const [active, setactive] = useState('Pending for Approval')

    useEffect(() => {
        const data = []
        usersData.map((item, index) => {
            data.push({
                name: item.user_first_name + ' ' + item.user_middle_name + ' ' + item.user_last_name,
                mobile: item.user_mobile,
                ppo: item.user_ppo_number,
                dor: item.user_retd_date,
                regamount: item.user_amount,
                address: item.user_address,
                birth_date: item.user_birth_date,
                cmmnce_date: item.user_cmmnce_date,
                created_date: item.user_created_date,
                death_date: item.user_death_date,
                department: getDapartment(item.user_department),
                district: item.user_district,
                email: item.user_email,
                id: item.user_id,
                image: item.user_image,
                pension: item.user_pension,
                pension_type: getPensionType(item.user_pension_type),
                pincode: item.user_pincode,
                session: getSession(item.user_session),
                state: item.user_state,
                status: item.user_status
            })
        })
        setdata(data)
    }, [usersData, districts, states, departments, sessions])


    const getDapartment = (dep) => {
        const object = departments.find((i) => i.department_id == dep)
        return object?.department_name
    }

    const getSession = (session) => {
        const object = sessions.find((i) => i.session_id == session)
        return object?.session_name
    }

    const getPensionType = (type) => {
        if (type == 0) {
            return 'Pension'
        } else {
            return 'Family Pension'
        }
    }

    const [data, setdata] = useState([])

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            wrap: true
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
        },
        {
            name: 'PPO Number',
            selector: row => row.ppo,
        },
        {
            name: 'Date of Retd.',
            selector: row => row.dor,
        },
        {
            name: 'Reg. Amount',
            selector: row => row.regamount,
            width: '120px',
            center: true
        },
        {
            name: 'Action',
            selector: row => row.action,
            width: '120px',
            cell: (row) => <button style={{ fontSize: '12px' }} className='btn p-2 bg-lggray text-dark approveBtn' onClick={(e) => {
                dispatch(showUserDetails(row))
            }} id={row.ppo} > Approve</button >
        },
    ];

    const approvedCol = [
        {
            name: 'Name',
            selector: row => row.name,
            wrap: true
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
        },
        {
            name: 'PPO Number',
            selector: row => row.ppo,
        },
        {
            name: 'Date of Retd.',
            selector: row => row.dor,
        },
        {
            name: 'Reg. Amount',
            selector: row => row.regamount,
            width: '120px',
            center: true
        },
        {
            name: 'Action',
            selector: row => row.action,
            width: '120px',
            cell: (row) => <button style={{ fontSize: '12px' }} className='btn p-2 bg-lggray text-dark approveBtn' onClick={(e) => {
                dispatch(showUserDetails(row))
            }} id={row.ppo} >View</button >
        },
    ];

    const Users = () => {

        const filterData = data.filter((i) => i.status == 'pending')

        console.log(filterData)

        return (
            <>
                <div className='container bg-white p-2'>
                    <Table data={filterData} colms={columns} needExport />
                </div>
            </>
        )
    }

    const ApprovedUsers = () => {

        const filterData = data.filter((i) => i.status == 'approved')

        return (
            <>
                <div className='container bg-white p-2'>
                    <Table data={filterData} colms={approvedCol} needExport />
                </div>
            </>
        )
    }


    const tabs = [{ name: 'Pending for Approval', data: Users() }, { name: 'Approved', data: ApprovedUsers() }, { name: 'Rejected', data: '' }]

    return (
        <>
            <ApproveModal
                display={popUp === 'approve' ? 'block' : 'none'}
                username={selectedUser.ppo}
                id={selectedUser.id}
                approveOnClick={(v) => dispatch(approveUser(v))}
                button1Click={() => dispatch(showPopUp(null))}
            />
            <UserCard
                user={selectedUser}
                display={popUp === 'userDetail' ? 'block' : 'none'}
                button1={'Cancel'}
                button2={'Approve'}
                button3={'Reject'}
                button4={'Edit'}
                button1Click={() => dispatch(showPopUp(null))}
                button2Click={() => dispatch(showPopUp('approve'))}
                button3Click={() => ''}
                button4Click={() => ''}
            />
            <div className="p-2 rounded-3">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    {tabs.map((item, i) => (
                        <a onClick={() => {
                            setactive(item.name)
                        }} className={`nav-link p-3 mt-2 rounded-2 ${active == item.name && 'active'}`} id={item.name} data-bs-toggle="pill"
                            data-bs-target={`#${item.name}`} type="button" role="tab"
                            aria-controls={item.name} aria-selected="true">
                            <div className="align-items-center p-0">
                                <span style={{ fontSize: '12px ', marginLeft: '8px' }} className="fw-bold">{item.name}</span>
                            </div>
                        </a>
                    ))}
                </ul>
            </div>
            <div className="tab-content" id="v-pills-tabContent">
                {tabs.map((item, i) => (
                    <div className={`tab-pane show ${active === item.name && 'active'}`} id={item.name} role="tabpanel"
                        aria-labelledby={item.name} tabindex="0">
                        <div className="card mainCard p-2">
                            <div className="bg-white card-header fs-2">
                                <div className="d-flex justify-content-between">
                                    <div className="fw-bold fs-5">
                                        {item.name}
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                {item.data}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Users
