import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from './combineReducer'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga/rootSaga'
import persistStore from 'redux-persist/es/persistStore'


const sagaMiddleware = createSagaMiddleware()

const Store = configureStore({
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware(), sagaMiddleware
    ]
})

export const persistor = persistStore(Store)

sagaMiddleware.run(rootSaga)

export default Store