import React from 'react'

const Confirmation = ({ display, heading, button1, button2, button1Click, button2Click, description, button2Color }) => {
    return (
        <div id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" style={{ display: display, paddingRight: '15px' }}>
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLiveLabel">{heading}</h5>
                    </div>
                    <div class="modal-body">
                        <p>{description}</p>
                    </div>
                    <div class="modal-footer">
                        {button1 ? <button onClick={button1Click} type="button" class="btn btn-secondary">{button1}</button> : null}
                        {button2 ? <button onClick={button2Click} type="button" class={`btn ${button2Color}`}>{button2}</button> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Confirmation