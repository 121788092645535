import axios from "axios";

const Api = axios.create({
    baseURL: 'https://pwa.amandhiman.in/api/',
});

Api.interceptors.request.use(
    async (config) => {
        config.headers["content-type"] = "application/x-www-form-urlencoded";
        config.headers.Authorization = localStorage.getItem('user');
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default Api;
