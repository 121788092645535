import React, { useContext, useEffect } from 'react'
import { Link } from "react-router-dom";
import AdminHeader from '../Components/AdminHeder';

const Home = () => {


    const array = [
        { heading: 'Total Dummy', value: '6', desc: 'View', icon: 'bi bi-people-fill', bg: 'bg-orange', onClick: "/users" },
        { heading: 'Total Dummy', value: '6', desc: 'View', icon: 'bi bi-person-plus', bg: 'bg-blue', onClick: '/uploadCirculars' },
        { heading: 'Total Dummy', value: '6', desc: 'View', icon: 'bi bi-file-spreadsheet', bg: 'bg-green', onClick: '' },
        { heading: 'Total Dummy', value: '6', desc: 'View', icon: 'bi bi-calendar3', bg: 'bg-red', onClick: '' },
        { heading: 'Total Dummy', value: '6', desc: 'View', icon: 'bi bi-info-circle', bg: 'bg-lggray', onClick: '' },
        { heading: 'Total Dummy', value: '6', desc: 'View', icon: 'bi bi-cloud-arrow-up-fill', bg: 'bg-green', onClick: '' },
        { heading: 'Total Dummy', value: '6', desc: 'View', icon: 'bi bi-box-arrow-right', bg: 'bg-red', onClick: '' },
    ]

    return (
        <>
            <div className='container bg-white mt-5 p-5 rounded-2'>
                <h2>Dashboard</h2>
                <div className='row'>
                    {array.map((item) => {
                        return (
                            <div className="col-md-3 col-sm-6">
                                <div className={`card-box ${item.bg}`}>
                                    <div className="inner">
                                        <h3 className='text-dark'>{item.value}</h3>
                                        <p className='text-dark'> {item.heading} </p>
                                    </div>
                                    <div className="icon">
                                        <i className={`text-muted ${item.icon}`} aria-hidden="true"></i>
                                    </div>
                                    <Link to={item.onClick} className="text-light fw-bold card-box-footer">{item.desc}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div >
        </>
    )
}

export default Home


