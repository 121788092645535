import { takeLatest, takeEvery, call, put, delay, select } from "redux-saga/effects"
import { regFailed, regSuccess, setFetchedData, showError } from "../reducers/joining";
import Api from '../../utils/Api'

function* handleNewRegistration(data) {
    console.log(data)
    var form = new FormData();
    form.append('firstname', data.payload.fname);
    form.append('middlename', data.payload.mname);
    form.append('lastname', data.payload.lname);
    form.append('mobile', data.payload.mobile);
    form.append('email', data.payload.email);
    form.append('image', data.payload.pic);
    form.append('pponumber', data.payload.ppo);
    form.append('department', data.payload.department);
    form.append('pension', data.payload.basic_pension);
    form.append('pensiontype', data.payload.pensionType);
    form.append('birthdate', data.payload.dob);
    form.append('deathdate', data.payload.dod);
    form.append('cmmncedate', data.payload.doc);
    form.append('retddate', data.payload.dor);
    form.append('address', data.payload.address);
    form.append('state', data.payload.state);
    form.append('pincode', data.payload.pincode);
    form.append('district', data.payload.district);
    form.append('session', data.payload.session);
    form.append('amount', data.payload.amount);
    form.append('createddate', data.payload.reg_date);
    try {
        const response = yield Api.post('/user/new_user', form)
        const res = response.data
        console.log(res, form, 'User')
        if (res.code == 1) {
            yield delay(2000)
            yield put(regSuccess(res.message))
        } else {
            yield put(regFailed(res.message))
        }
    } catch (error) {
        console.log(error)
    }
}


function* fetchAllDssd() {
    try {
        const response = yield Api.get('/lump/all')
        const res = response.data
        console.log(res)
        if (res.code == 1) {
            yield put(setFetchedData(res))
        } else {
            yield put(showError(res.message))
        }
    } catch (error) {
        console.log(error)
    }
}


function* joinSaga() {
    yield takeLatest('joining/fetchNewUser', handleNewRegistration)
    yield takeLatest('joining/fetchdssd', fetchAllDssd)
}

export default joinSaga