import { Formik } from 'formik'
import React, { useEffect } from 'react'
import Loader from '../Components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLogin } from '../redux/reducers/auth'

const Login = () => {

    const dispatch = useDispatch()

    const { loading, token, error } = useSelector(state => state.auth)

    console.log(loading)

    return (
        <div className="pt-5 pb-5 mt-0 align-items-center d-flex bg-lggray" style={{ minHeight: '100vh' }}>
            <div className="container">
                <div className='d-flex justify-content-center'>
                    <div className='col-md-4 pb-5 rounded-4 border border-4 border-white p-3 bg-white'>
                        {error ?
                            <div class="alert alert-danger d-flex align-items-center" role="alert">
                                <i class="bi bi-x-circle-fill mx-2"></i>
                                <div>
                                    {error}
                                </div>
                            </div> : null}
                        <Formik
                            initialValues={{ username: '', password: '' }}
                            onSubmit={(values) => dispatch(fetchLogin(values))}
                            validationSchema={''}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form className='mt-4 ' onSubmit={handleSubmit}>
                                    <img className='rounded-5 mx-auto d-block ' src="/media/pwaLogo.png" height={150} width={150} />
                                    <div className="mb-3 px-5 mt-5">
                                        <label for="email" className="form-label text-dark">Username</label>
                                        <input placeholder='Enter' onChange={handleChange} type="text" name='username' value={values.username} className="form-control py-2" id="username" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="mb-3 px-5">
                                        <label for="password" className="form-label text-dark">Password</label>
                                        <input placeholder='Enter' onChange={handleChange} type="password" value={values.password} name='password' className="form-control py-2" id="password" aria-describedby="emailHelp" />
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button className='btn bg-dark col-md-6 py-2' type="submit">
                                            {loading ? <Loader /> : <span className='text-white'>Log In</span>}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Login
