import React from 'react'
import { Formik } from 'formik'
import { PasswordSchema } from '../validations'

const ApproveModal = ({ display, username, id, button1Click, approveOnClick }) => {

    return (
        <div id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" style={{ display: display, paddingRight: '15px' }}>
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLiveLabel">Approve:&nbsp;{username}</h5>
                    </div>
                    <div class="modal-body">
                        <Formik
                            enableReinitialize
                            initialValues={{ username: username, password: '', user_id: id }}
                            onSubmit={(values) => approveOnClick(values)}
                            validationSchema={PasswordSchema}
                        >
                            {({ handleSubmit, errors, touched, values, handleChange }) => (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <div class="mb-3">
                                            <label for="" class="form-label">Username</label>
                                            <input value={values.username} disabled type="text" class="form-control" name="username" id="username" aria-describedby="helpId" placeholder="" />
                                            <small id="helpId" class="form-text text-danger">{errors.username && touched.username ? errors.username : null}</small>
                                        </div>
                                        <div class="mb-3">
                                            <label for="" class="form-label">Password</label>
                                            <input type="text" onChange={handleChange} class="form-control" name="password" id="password" aria-describedby="helpId" placeholder="Enter Password" />
                                            <small id="helpId" class="form-text text-danger">{errors.password && touched.password ? errors.password : null}</small>
                                        </div>
                                        <div class="modal-footer">
                                            <button onClick={button1Click} type="button" class="btn btn-secondary">{'Cancel'}</button>
                                            <button type="submit" name="submit" id="submit" class="btn btn-success">{'Approve'}</button>
                                        </div>
                                    </form>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApproveModal