import { takeLatest, takeEvery, call, put, delay, select } from "redux-saga/effects"
import Api from '../../utils/Api'
import { circularUploaded, setCirculars, uploadFailed, circularDeleted, deletionFailed } from "../reducers/circulars";

var FormData = require('form-data');


function* uploadCircular(data) {
    var form = new FormData();
    form.append('circular_name', data.payload.name);
    form.append('circular_date', data.payload.date);
    form.append('circular_department', data.payload.department);
    form.append('circular_file', data.payload.file);
    form.append('circular_uploaded_on', data.payload.uploadDate);
    try {
        const response = yield Api.post('/circular/upload', form)
        const res = response.data
        console.log(res)
        if (res.code == 1) {
            yield delay(2000)
            yield put(circularUploaded(res.message))
        } else {
            yield put(uploadFailed(res.message))
        }
    } catch (error) {
        console.log(error)
    }
}

function* fetchCirculars() {
    try {
        const response = yield Api.get('/circular')
        const res = response.data
        if (res.code == 1) {
            yield put(setCirculars(res.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteCircular() {
    const id = yield select(state => state.circulars.deleteId)
    const params = {
        delete_circular_id: id
    }
    try {
        const response = yield Api.post('/circular/delete', params)
        const res = response.data
        if (res.code == 1) {
            yield delay(3000)
            yield put(circularDeleted(res.message))
        } else {
            yield put(deletionFailed(res.message))
        }
    } catch (error) {
        console.log(error)
    }
}


function* circularsSaga() {
    yield takeLatest('circulars/uploadCirculars', uploadCircular)
    yield takeLatest('circulars/fetchCirculars', fetchCirculars)
    yield takeLatest('circulars/fetchDeleteCircular', deleteCircular)

}

export default circularsSaga