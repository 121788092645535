import { createSlice } from '@reduxjs/toolkit'

const token = localStorage.getItem('user')

const slice = createSlice({
    name: 'auth',
    initialState: {
        loading: null,
        error: null,
        token: token || null,
        authenticated: null,
        user: ''
    },
    reducers: {
        fetchLogin: (auth, action) => {
            auth.loading = true;
        },
        loginVerified: (auth, action) => {
            const { user_token } = action.payload
            auth.loading = false;
            auth.user = action.payload;
            auth.token = user_token;
            localStorage.setItem('user', user_token)
        },
        loginFailed: (auth, action) => {
            auth.loading = false;
            auth.error = action.payload;
        },
        checkAuth: (auth, action) => {
            auth.loading = true;
        },
        authSuccess: (auth, action) => {
            auth.loading = false;
        },
        authFailed: (auth, action) => {
            auth.error = action.payload
            auth.token = null;
            auth.loading = false;
            localStorage.removeItem('user')
        },
    }
})


export const {
    fetchLogin,
    loginVerified,
    loginFailed,
    checkAuth,
    authSuccess,
    authFailed,
} = slice.actions

export default slice.reducer