import React, { useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'


const Main = ({ sidemenu }) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const active = useLocation();

    const logOut = () => {
        localStorage.clear();
        navigate('/login')
        window.location.reload()
    }

    return (
        <>
            <header class="navbar navbar-dark bg-dark flex-md-nowrap p-0 shadow">
                <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">
                    <img
                        src="/media/pwaLogo.png"
                        height="50"
                        width="50"
                    />
                    <span class="fs-5 ps-2 fw-semibold">P.W.A SUNAM</span>
                </a>
                <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-nav d-none d-sm-block">
                    <div className="d-flex align-items-center px-5">
                        <div class="dropdown">
                            <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img
                                    src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                                    className="rounded-circle"
                                    height="25"
                                    alt="Black and White Portrait of a Man"
                                    loading="lazy"
                                />
                            </button>
                            <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-lg-end position-absolute">
                                {sidemenu ?
                                    <>
                                        <li>
                                            <Link to={'/profile'} className={`dropdown-item text-light ${active == 'MyProfile' && 'active'}`}>My Profile</Link>
                                        </li>
                                        <li><hr class="dropdown-divider bg-light" /></li>
                                    </>
                                    : null}
                                <li><a onClick={() => logOut()} class="dropdown-item" href="#">Log Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            {/* <div className='row vh-100'>
                <div id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-dark sidebar collapse">
                    <a href="/" class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
                        <svg class="bi me-2" width="30" height="24"></svg>
                    </a>
                    <ul class="list-unstyled ps-0">
                        <li class="mb-1">
                            <button class="btn text-light btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
                                Home
                            </button>
                            <div class="collapse show" id="home-collapse">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">
                                    <li><a href="#" class="link-light rounded">Overview</a></li>
                                    <li><a href="#" class="link-light rounded">Updates</a></li>
                                    <li><a href="#" class="link-light rounded">Reports</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="mb-1">
                            <button class="btn text-light btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">
                                Dashboard
                            </button>
                            <div class="collapse" id="dashboard-collapse">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><a href="#" class="link-light rounded">Overview</a></li>
                                    <li><a href="#" class="link-light rounded">Weekly</a></li>
                                    <li><a href="#" class="link-light rounded">Monthly</a></li>
                                    <li><a href="#" class="link-light rounded">Annually</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="mb-1">
                            <button class="btn text-light btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#orders-collapse" aria-expanded="false">
                                Orders
                            </button>
                            <div class="collapse" id="orders-collapse">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><a href="#" class="link-light rounded">New</a></li>
                                    <li><a href="#" class="link-light rounded">Processed</a></li>
                                    <li><a href="#" class="link-light rounded">Shipped</a></li>
                                    <li><a href="#" class="link-light rounded">Returned</a></li>
                                </ul>
                            </div>
                        </li>
                        <li class="border-top my-3"></li>
                        <li class="mb-1">
                            <button class="btn btn-toggle text-light align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">
                                Account
                            </button>
                            <div class="collapse" id="account-collapse">
                                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><a href="#" class="link-light rounded">New...</a></li>
                                    <li><a href="#" class="link-light rounded">Profile</a></li>
                                    <li><a href="#" class="link-light rounded">Settings</a></li>
                                    <li><a href="#" class="link-light rounded">Sign out</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div> */}
            <div className='row vh-100'>
                {sidemenu ? <div id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-dark sidebar collapse" >
                    <div class="position-sticky pt-3">
                        <ul class="nav flex-column">
                            <li class="nav-item">
                                <span data-feather="home"></span>
                                <Link to={'/home'} className={`nav-link ${active.pathname == '/home' && 'active'}`} >Dashboard</Link>
                            </li>
                            <li class="nav-item">
                                <span data-feather="file"></span>
                                <Link to={'/uploadCirculars'} className={`nav-link ${active.pathname == '/uploadCirculars' && 'active'}`}>Circulars</Link>
                            </li>
                            <li class="nav-item">
                                <span data-feather="Others"></span>
                                <Link to={'/lump'} className={`nav-link ${active.pathname == '/lump' && 'active'}`}>Others</Link>
                            </li>
                            <li class="nav-item">
                                <span data-feather="users"></span>
                                <Link to={'/users'} className={`nav-link ${active.pathname == '/users' && 'active'}`} >Users</Link>
                            </li>
                        </ul>
                    </div>
                </div> : null}
                <div className='col-md-9 mx-auto pb-5'>
                    <Outlet />
                </div>
            </div>
        </>
    )
}


export default Main;

