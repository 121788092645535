import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'joining',
    initialState: {
        loading: null,
        error: null,
        popUp: null,
        departments: [],
        states: [],
        sessions: [],
        districts: []
    },
    reducers: {
        showError: (joining, action) => {
            joining.error = action.payload;
        },
        showPopUp: (joining, action) => {
            joining.popUp = action.payload;
        },
        fetchNewUser: (joining, action) => {
            joining.loading = true
        },
        regSuccess: (joining, action) => {
            joining.loading = false;
            joining.popUp = 'reg_success'
        },
        regFailed: (joining, action) => {
            joining.loading = false
            joining.error = action.payload
        },
        fetchdssd: (joining, action) => {
            joining.loading = true
        },
        setFetchedData: (joining, action) => {
            const { districts, states, departments, sessions } = action.payload
            joining.districts = districts;
            joining.departments = departments;
            joining.sessions = sessions;
            joining.states = states;
            joining.loading = false;
        },
    }
})


export const {
    showError,
    showPopUp,
    fetchNewUser,
    regSuccess,
    regFailed,
    fetchdssd,
    setFetchedData
} = slice.actions

export default slice.reducer





