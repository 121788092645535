import React, { useEffect } from "react";
import { Provider } from 'react-redux'
import { PersistGate } from "redux-persist/integration/react";
import Store, { persistor } from '../src/redux/store'
import Loader from "./Components/Loader";
import AppRoutes from './routes'


const App = () => {

  return (
    <Provider store={Store}>
      <PersistGate persistor={persistor} loading={<Loader />}>
        <AppRoutes />
      </PersistGate>
    </Provider>
  )
}

export default App