import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'news',
    initialState: {
        loading: null,
        error: null,
        popUp: null,
        message: '',
        newsData: [],
        deleteId: null
    },
    reducers: {
        showError: (news, action) => {
            news.error = action.payload;
        },
        showPopUp: (news, action) => {
            news.popUp = action.payload;
        },
        uploadnews: (news, action) => {
            news.loading = true;
        },
        newsUploaded: (news, action) => {
            news.popUp = 'uploaded';
            news.message = action.payload;
            news.loading = false;
        },
        uploadFailed: (news, action) => {
            news.error = action.payload;
            news.loading = false;
        },
        deletionFailed: (news, action) => {
            news.error = action.payload;
            news.loading = false;
        },
        fetchnews: (news, action) => {
            news.loading = true;
        },
        setnews: (news, action) => {
            news.newsData = action.payload;
            news.loading = false;
        },
        deletenews: (news, action) => {
            news.deleteId = action.payload;
            news.popUp = 'deleteConfirmation'
        },
        fetchDeletenews: (news, action) => {
            news.loading = true
        },
        newsDeleted: (news, action) => {
            news.popUp = 'deleted';
            news.loading = false
        },
    }
})


export const {
    showError,
    showPopUp,
    uploadnews,
    newsUploaded,
    uploadFailed,
    fetchnews,
    setnews,
    deletenews,
    fetchDeletenews,
    newsDeleted,
    deletionFailed
} = slice.actions

export default slice.reducer