import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, Navigate, Outlet } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Circular from "./pages/Circular";
import Users from "./pages/Users";
import Login from "./pages/Login";
import Join from "./pages/Join";
import Index from "./pages/Index";
import AuthProvider from "./context/AuthProvider";
import { useSelector } from 'react-redux'
import News from "./pages/News";
import Main from "./pages/Main";
import Lump from "./pages/Lump";
import CircularNotification from "./pages/CircularNotification";
import OutletMain from "./pages/OutletMain";

const AppRoutes = () => {

    const { token, user } = useSelector(state => state.auth)

    return (
        <AuthProvider>
            <BrowserRouter>
                {token ?
                    <Routes>
                        {user.user_type == 1 ? <>
                            <Route path="/" element={<Main sidemenu />}>
                                <Route path='/*' element={<Home />} />
                                <Route path='/home' element={<Home />} />
                                <Route path='/users' element={<Users />} />
                                <Route path='/uploadCirculars' element={<Circular />} />
                                <Route path='/profile' element={<Profile />} />
                                <Route path='/uploadNews' element={<News />} />
                                <Route path='/lump' element={<Lump />} />
                            </Route>
                        </>
                            :
                            <>
                                <Route path="/" element={<Main />}>
                                    <Route path='/*' element={<Profile />} />
                                    <Route path='/profile' element={<Profile />} />
                                </Route>
                            </>}

                    </Routes>
                    :
                    <Routes>
                        <Route path='/login' element={<Login />} />
                        <Route path="/" element={<OutletMain />}>
                            <Route path='/join' element={<Join />} />
                            <Route path='/circulars' element={<CircularNotification />} />
                            <Route path='/' element={<Index />} />
                        </Route>
                    </Routes>}
            </BrowserRouter>
        </AuthProvider>
    )
}

export default AppRoutes
