import { takeLatest, takeEvery, call, put, delay, select } from "redux-saga/effects"
import { loginVerified, loginFailed, authSuccess, authFailed } from "../reducers/auth"
import Api from '../../utils/Api'
import { Navigate } from 'react-router-dom'



function* checkAuthentication() {
    const autheticationToken = yield select(state => state.auth.token)
    const params = {
        login_token: autheticationToken
    }
    try {
        const response = yield Api.post('/auth/verify', params)
        const res = response.data
        console.log(res)
        if (res.code == 1) {
            yield put(authSuccess())
        } else {
            yield put(authFailed(res.message))
            { <Navigate to={'/login'} replace={true} /> }
        }
    } catch (error) {
        console.log(error)
    }

}

function* fetchLoginDetails(data) {
    const params = {
        username: data.payload.username,
        password: data.payload.password,
    }
    try {
        const response = yield Api.post('/auth/login', params)
        const res = response.data
        console.log(res, 'login')
        if (res.code == 1) {
            yield put(loginVerified(res.user))
        } else {
            yield put(loginFailed(res.message))
        }
    } catch (error) {
        console.log(error)
    }
}


function* authSaga() {
    yield takeLatest('auth/fetchLogin', fetchLoginDetails)
    yield takeLatest('auth/checkAuth', checkAuthentication)
}

export default authSaga