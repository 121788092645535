import { combineReducers } from 'redux'
import authReducer from './reducers/auth';
import circularsReducer from './reducers/circulars';
import joiningReducer from './reducers/joining';
import lumpReducer from './reducers/lump';
import usersReducer from './reducers/users';

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'auth',
    storage: storage,
    whitelist: ['user']
};

const rootReducer = combineReducers({
    auth: persistReducer(persistConfig, authReducer),
    circulars: circularsReducer,
    joining: joiningReducer,
    users: usersReducer,
    lump: lumpReducer
})

export default rootReducer;