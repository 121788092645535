import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'users',
    initialState: {
        loading: null,
        error: null,
        popUp: null,
        usersData: [],
        selectedUser: ''
    },
    reducers: {
        showError: (users, action) => {
            users.error = action.payload;
        },
        setLoading: (users, action) => {
            users.loading = action.payload;
            users.error = null;
        },
        showPopUp: (users, action) => {
            users.popUp = action.payload;
        },
        fetchUsers: (users, action) => {
            users.loading = true
        },
        setUsers: (users, action) => {
            users.usersData = action.payload;
            users.loading = false
        },
        showUserDetails: (users, action) => {
            users.selectedUser = action.payload;
            users.popUp = 'userDetail';
            users.loading = false
        },
        badResponse: (users, action) => {
            users.error = action.payload
            users.loading = false;
        },
        approveUser: (users, action) => {
            users.loading = true;
        }
    }
})


export const {
    showError,
    setLoading,
    showPopUp,
    fetchUsers,
    setUsers,
    showUserDetails,
    approveUser,
    badResponse,
} = slice.actions

export default slice.reducer
