import { Formik } from 'formik'
import moment from 'moment'
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Confirmation from '../Components/Confirmation'
import Header from '../Components/Header'
import Loader from '../Components/Loader'
import { fetchdssd, fetchNewUser, showPopUp } from '../redux/reducers/joining'
import { joinSchema } from '../validations'
import { Link } from "react-router-dom";
import { fetchLump } from '../redux/reducers/lump'


const Join = () => {

    const dispatch = useDispatch()

    const { loading, error, popUp, districts, states, departments, sessions } = useSelector(state => state.joining)

    const upload = () => {
        document.getElementById("pic").click()
    }

    useEffect(() => {
        dispatch(fetchdssd())
    }, [])



    return (
        <div className='container-fluid'>
            <div className='container p-md-5 bg-white'>
                {loading ? <div className='loader'>
                    <div className='loader_center'>
                        <Loader />
                    </div>
                </div> : null}
                <Confirmation
                    display={popUp === 'reg_success' ? 'block' : 'none'}
                    heading={'Registration Successfull'}
                    description={'Note: Your Registration Status is Pending till it was confirmend by Pwa'}
                    button2={'OK'}
                    button2Color={'btn-success'}
                    button2Click={() => {
                        dispatch(showPopUp(null))
                        window.location.reload()
                    }}
                />
                <Formik
                    initialValues={{
                        fname: '', mname: '', lname: '', mobile: '', email: '', ppo: '', department: '', basic_pension: '', pensionType: '', dob: '', dod: '',
                        address: '', dor: '', doc: '', state: '', district: '', pincode: '', reg_date: moment(new Date()).format('DD-MM-YYYY'), session: '', amount: '', pic: ''
                    }}
                    onSubmit={(values) => dispatch(fetchNewUser(values))}
                    validationSchema={joinSchema}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form className='mt-4' onSubmit={handleSubmit}>
                            <div className='card mt-3' >
                                <div className='bg-dark p-2 rounded'>
                                    <span className='text-light fw-bold' >Personal Details</span>
                                </div>
                                <div className='row p-4'>
                                    <div className='col-md-9 mt-md-5'>
                                        <div className='row'>
                                            <div class="mb-3 col-sm-12 col-md-4">
                                                <label class="form-label">First Name</label>
                                                <input onChange={handleChange} type="text" value={values.fname} class="form-control py-2" name="fname" id="fname" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.fname && touched.fname && errors.fname}</span>
                                            </div>
                                            <div class="mb-3 col-sm-12 col-md-4">
                                                <label class="form-label">Middle Name</label>
                                                <input onChange={handleChange} value={values.mname} type="text" class="form-control py-2" name="mname" id="mname" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.mname && touched.mname && errors.mname}</span>
                                            </div>
                                            <div class="mb-3 col-sm-12 col-md-4">
                                                <label class="form-label">Last Name</label>
                                                <input onChange={handleChange} type="text" value={values.lname} class="form-control py-2" name="lname" id="lname" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.lname && touched.lname && errors.lname}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label">Mobile Number</label>
                                                <input onChange={handleChange} value={values.mobile} type="text" class="form-control py-2" name="mobile" id="mobile" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.mobile && touched.mobile && errors.mobile}</span>
                                            </div>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label">E-mail</label>
                                                <input onChange={handleChange} value={values.email} type="text" class="form-control py-2" name="email" id="email" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.email && touched.email && errors.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 card d-flex align-items-center py-2 ms-md-5">
                                        <img className='profilePic' src={values.pic != '' ? URL.createObjectURL(values.pic) : '/media/avatar.jpg'} />
                                        <div class="card-body">
                                            <input onChange={(event) => { setFieldValue("pic", event.currentTarget.files[0]) }} type="file" hidden class="form-control py-2" name="pic" id="pic" placeholder="" />
                                            <a onClick={() => upload()} name="" id="" class="btn btn-secondary" href="#" role="button">Upload</a>
                                        </div>
                                        <span className='text-danger errorInput'>{errors.pic && touched.pic && errors.pic}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-5' >
                                <div className='bg-dark p-2 rounded'>
                                    <span className='text-light fw-bold'>Pension Details</span>
                                </div>
                                <div className='p-4'>
                                    <div className=''>
                                        <div className='row'>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label">PPO Number</label>
                                                <input onChange={handleChange} value={values.ppo} type="text" class="form-control py-2" name="ppo" id="ppo" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.ppo && touched.ppo && errors.ppo}</span>
                                            </div>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label" for="department">Department</label>
                                                <select onChange={handleChange} value={values.department} class="form-select py-2" name="department" id="department">
                                                    <option selected>Select</option>
                                                    {departments.map((item, index) => {
                                                        return (
                                                            <option value={item.department_id}>{item.department_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <span className='text-danger errorInput'>{errors.department && touched.department && errors.department}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label">Basic Pension</label>
                                                <input onChange={handleChange} value={values.basic_pension} type="text" class="form-control py-2" name="basic_pension" id="basic_pension" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.basic_pension && touched.basic_pension && errors.basic_pension}</span>
                                            </div>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label">Pension Type</label>
                                                <div className='row ps-3'>
                                                    <div class="form-check col-md-6">
                                                        <input onChange={handleChange} value={0} class="form-check-input" type="radio" name="pensionType" id="flexRadioDefault1" />
                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                            Pension
                                                        </label>
                                                    </div>
                                                    <div class="form-check col-md-6">
                                                        <input onChange={handleChange} value={1} class="form-check-input" type="radio" name="pensionType" id="flexRadioDefault2" />
                                                        <label class="form-check-label" for="flexRadioDefault2">
                                                            Family Pension
                                                        </label>
                                                    </div>
                                                    <span className='text-danger errorInput'>{errors.pensionType && touched.pensionType && errors.pensionType}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            {values.pensionType == 0 ? <div class="mb-3 col-sm-12 col-md-4">
                                                <label class="form-label">Birth Date</label>
                                                <input onChange={handleChange} value={values.dob} type="date" class="form-control py-2" name="dob" id="dob" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.dob && touched.dob && errors.dob}</span>
                                            </div> :
                                                <div class="mb-3 col-sm-12 col-md-4">
                                                    <label class="form-label">Death Date</label>
                                                    <input onChange={handleChange} value={values.dod} type="date" class="form-control py-2" name="dod" id="dod" placeholder="Enter" />
                                                    <span className='text-danger errorInput'>{errors.dod && touched.dod && errors.dod}</span>
                                                </div>}
                                            <div class="mb-3 col-sm-12 col-md-4">
                                                <label class="form-label">Retirement Date</label>
                                                <input onChange={handleChange} value={values.dor} type="date" class="form-control py-2" name="dor" id="dor" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.dor && touched.dor && errors.dor}</span>
                                            </div>
                                            <div class="mb-3 col-sm-12 col-md-4">
                                                <label class="form-label">Commencement Date</label>
                                                <input onChange={handleChange} value={values.doc} type="date" class="form-control py-2" name="doc" id="doc" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.doc && touched.doc && errors.doc}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-5' >
                                <div className='bg-dark p-2 rounded'>
                                    <span className='text-light fw-bold'>Address Details</span>
                                </div>
                                <div className='p-4'>
                                    <div className=''>
                                        <div className='row'>
                                            <div class="mb-3 col-sm-12 col-md-12">
                                                <label class="form-label">Address</label>
                                                <textarea onChange={handleChange} value={values.address} type="text-area" class="form-control p-3" name="address" id="address" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.address && touched.address && errors.address}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label" for="department">State</label>
                                                <select onChange={handleChange} value={values.state} class="form-select py-2" name="state" id="state">
                                                    <option selected>Select</option>
                                                    {states.map((item, index) => {
                                                        return (
                                                            <option value={item.state_id}>{item.state_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <span className='text-danger errorInput'>{errors.state && touched.state && errors.state}</span>
                                            </div>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label">District</label>
                                                <select onChange={handleChange} value={values.district} class="form-select py-2" name="district" id="district">
                                                    <option selected>Select</option>
                                                    {districts.map((item, index) => {
                                                        return (
                                                            <option value={item.district_id}>{item.district_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <span className='text-danger errorInput'> {errors.district && touched.district && errors.district}</span>
                                            </div>
                                        </div>
                                        <div class="mb-3 col-sm-12 col-md-6">
                                            <label class="form-label">Pincode</label>
                                            <input onChange={handleChange} value={values.pincode} type="text" class="form-control py-2" name="pincode" id="pincode" placeholder="Enter" />
                                            <span className='text-danger errorInput'>{errors.pincode && touched.pincode && errors.pincode}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-5' >
                                <div className='bg-dark p-2 rounded'>
                                    <span className='text-light fw-bold'>Membership Details</span>
                                </div>
                                <div className='p-4'>
                                    <div className=''>
                                        <div className='row'>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label" for="session">Session</label>
                                                <select onChange={handleChange} value={values.session} class="form-select py-2" name="session" id="session">
                                                    <option selected>Select</option>
                                                    {sessions.map((item, index) => {
                                                        return (
                                                            <option value={item.session_id}>{item.session_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <span className='text-danger errorInput'> {errors.session && touched.session && errors.session}</span>
                                            </div>
                                            <div class="mb-3 col-sm-12 col-md-6">
                                                <label class="form-label">Amount</label>
                                                <input onChange={handleChange} value={values.amount} type="text" class="form-control py-2" name="amount" id="amount" placeholder="Enter" />
                                                <span className='text-danger errorInput'>{errors.amount && touched.amount && errors.amount}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {error ? <div class="alert alert-danger mt-md-4" role="alert">
                                {error}
                            </div> : null}
                            <div class="mt-5 mb-5 text-center">
                                <button type="submit" name="submit" id="submit" class="btn btn-success p-md-2 p-sm-2 text-white fw-bold fs-5 col-sm-12 col-md-4">Submit</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Join