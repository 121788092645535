import React from 'react'

const UserCard = ({ display, user, button1, button2, button3, button4, button1Click, button3Click, button2Click, button4Click }) => {
    return (
        <div id="exampleModalLive" className="modal fade show" tabindex="-1" role="dialog" style={{ display: display, paddingRight: '15px' }}>
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="p-4 modal-header">
                        <div className='col-md-4 col-sm-12'>
                            <img src={user.image} style={{ height: 120, width: 120, borderRadius: 60 }} />
                        </div>
                        <div className='col-md-8 col-sm-12 align-items-center'>
                            <h6><i class="bi bi-person-fill fs-5 me-1"></i>{user.name ? user.name : 'N/A'}</h6>
                            <h6><i class="bi bi-phone-fill fs-5 me-1"></i>{user.mobile ? user.mobile : 'N/A'}</h6>
                            <h6><i class="bi bi-postcard-fill fs-5 me-1"></i>{user.ppo ? user.ppo : 'N/A'}</h6>
                            <h6><i class="bi bi-geo-fill fs-5 me-1"></i>{user.address ? user.address : 'N/A'}</h6>
                            <h6><i class="bi bi-envelope-fill fs-5 me-1"></i>{user.email ? user.email : 'N/A'}</h6>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div className='row'>
                            <div className='col'>
                                <h6 className='fw-bold' >Date of Birth</h6>
                                <p class="fs-6">{user.birth_date}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Cmmnc. Date</h6>
                                <p class="fs-6">{user.cmmnce_date}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Form Date</h6>
                                <p class="fs-6">{user.created_date}</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='fw-bold'>Death Date</h6>
                                <p class="fs-6">{user.death_date ? user.death_date : 'N/A'}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Department</h6>
                                <p class="fs-6">{user.department ? user.department : 'N/A'}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Pension Type</h6>
                                <p class="fs-6">{user.pension_type ? user.pension_type : 'N /A'}</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='fw-bold'>Date of Retirement</h6>
                                <p class="fs-6">{user.dor ? user.dor : 'N/A'}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Pension</h6>
                                <p class="fs-6">₹{user.pension ? user.pension : 'N/A'}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Registration Amount</h6>
                                <p class="fs-6">₹{user.regamount ? user.regamount : 'N/A'}</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='fw-bold'>Session</h6>
                                <p class="fs-6">{user.session ? user.session : 'N/A'}</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <div>
                            {button3 ? (user.status == 'pending' ? <button onClick={button3Click} type="button" class={`btn btn-danger`}>{button3}</button> : null) : null}
                        </div>
                        <div>
                            {button1 ? <button onClick={button1Click} type="button" class="btn btn-secondary">{button1}</button> : null}
                            {button2 ? (user.status == 'pending' ? <button onClick={button2Click} type="button" class={`btn btn-success ms-2`}>{button2}</button> : null) : null}
                            {button4 ? (user.status == 'approved' ? <button onClick={button4Click} type="button" class={`btn btn-success ms-2`}>{button4}</button> : null) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default UserCard