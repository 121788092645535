import AuthContext from './AuthContext'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkAuth } from '../redux/reducers/auth'

const AuthProvider = ({ children }) => {

    const [auth, setauth] = useState(null)

    const { token } = useSelector(state => state.auth)

    const dispatch = useDispatch()

    useEffect(() => {
        if (token) {
            dispatch(checkAuth())
        }
    }, [])


    return (
        <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
    )
}

export default AuthProvider