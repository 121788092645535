import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, FieldArray } from 'formik';
import { joinSchema, uploadCircSchema } from '../validations';
import Loader from '../Components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import DatePickerField from '../Components/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import { uploadLump, fetchLump, deleteLump, uploadSession } from '../redux/reducers/lump';
import Swal from 'sweetalert2';

const Lump = () => {

    const dispatch = useDispatch()

    const [active, setactive] = useState('Departments')

    const { error, popUp, loading, dataArray } = useSelector(state => state.lump)

    const array = []

    useEffect(() => {
        dispatch(fetchLump(active))
    }, [active])


    const UploadDepartments = () => {
        return (
            <>
                <div className='col-md-12 bg-white rounded-2'>
                    <Formik
                        enableReinitialize
                        initialValues={{ departments: dataArray }}
                        onSubmit={values => dispatch(uploadLump({ data: values.departments, type: active }))}
                        render={({ values }) => (
                            <Form>
                                <FieldArray
                                    name="departments"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.departments && values.departments.length > 0 ? (
                                                values.departments.map((departments, index) => (
                                                    <div className='row align-items-end mt-2' key={index}>
                                                        <div className='col-md-6 col-sm-12' >
                                                            <Field className='form-control p-2' name={`departments.${index}.name`} />
                                                        </div>
                                                        <div className='col-md-1 col-sm-12' >
                                                            <button
                                                                type="button"
                                                                className='btn btn-danger p-2'
                                                                onClick={() => {
                                                                    if (!departments?.id) {
                                                                        arrayHelpers.remove(index)
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Are you sure?',
                                                                            text: "You won't be able to revert this!",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            confirmButtonText: 'Yes, delete it!'
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                arrayHelpers.remove(index)
                                                                                dispatch(deleteLump({ id: departments.id, type: active }))
                                                                            }
                                                                        })
                                                                    }
                                                                }} // remove a friend from the list
                                                            >
                                                                <i class="bi bi-trash"></i>
                                                            </button>
                                                        </div>
                                                        <div className='col-md-3 col-sm-12' >
                                                            {index == 0 ? <button
                                                                type="button"
                                                                className='btn btn-success p-2'
                                                                onClick={() => {
                                                                    arrayHelpers.insert(index, '')
                                                                }}
                                                            // onClick={() => } // insert an empty string at a position
                                                            >
                                                                Add New
                                                            </button> : null}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" className='btn btn-success' onClick={() => arrayHelpers.push('')}>
                                                    Add Department
                                                </button>
                                            )}
                                            {values.departments.length ? <div>
                                                <button className='btn btn-primary mt-2' type="submit">Submit</button>
                                            </div> : null}
                                        </div>
                                    )}
                                />
                            </Form>
                        )}
                    />
                </div>
            </>
        )
    }

    const UploadState = () => {
        return (
            <>
                <div className='col-md-12 bg-white rounded-2'>
                    <Formik
                        enableReinitialize
                        initialValues={{ state: dataArray }}
                        onSubmit={values => dispatch(uploadLump({ data: values.state, type: active }))}
                        render={({ values }) => (
                            <Form>
                                <FieldArray
                                    name="state"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.state && values.state.length > 0 ? (
                                                values.state.map((state, index) => (
                                                    <div className='row align-items-end mt-2' key={index}>
                                                        <div className='col-md-6 col-sm-12' >
                                                            <Field className='form-control p-2' name={`state.${index}.name`} />
                                                        </div>
                                                        <div className='col-md-1 col-sm-12' >
                                                            <button
                                                                type="button"
                                                                className='btn btn-danger p-2'
                                                                onClick={() => {
                                                                    if (!state?.id) {
                                                                        arrayHelpers.remove(index)
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Are you sure?',
                                                                            text: "You won't be able to revert this!",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            confirmButtonText: 'Yes, delete it!'
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                arrayHelpers.remove(index)
                                                                                dispatch(deleteLump({ id: state.id, type: active }))
                                                                            }
                                                                        })
                                                                    }
                                                                }} // remove a friend from the list
                                                            >
                                                                <i class="bi bi-trash"></i>
                                                            </button>
                                                        </div>
                                                        <div className='col-md-3 col-sm-12' >
                                                            {index == 0 ? <button
                                                                type="button"
                                                                className='btn btn-success p-2'
                                                                onClick={() => {
                                                                    arrayHelpers.insert(index, '')
                                                                }}
                                                            // onClick={() => } // insert an empty string at a position
                                                            >
                                                                Add New
                                                            </button> : null}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" className='btn btn-success' onClick={() => arrayHelpers.push('')}>
                                                    Add State
                                                </button>
                                            )}
                                            {values.state.length ? <div>
                                                <button className='btn btn-primary mt-2' type="submit">Submit</button>
                                            </div> : null}
                                        </div>
                                    )}
                                />
                            </Form>
                        )}
                    />
                </div>
            </>
        )
    }

    const UploadSessions = () => {
        return (
            <>
                <div className='col-md-12 bg-white rounded-2'>
                    <Formik
                        enableReinitialize
                        initialValues={{ sessions: dataArray }}
                        onSubmit={values => dispatch(uploadLump({ data: values.sessions, type: active }))}
                        render={({ values }) => (
                            <Form>
                                <FieldArray
                                    name="sessions"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.sessions && values.sessions.length > 0 ? (
                                                values.sessions.map((sessions, index) => (
                                                    <div className='row align-items-end mt-2' key={index}>
                                                        <div className='col-md-6 col-sm-12' >
                                                            <Field className='form-control p-2' name={`sessions.${index}.name`} />
                                                        </div>
                                                        <div className='col-md-1 col-sm-12' >
                                                            <button
                                                                type="button"
                                                                className='btn btn-danger p-2'
                                                                onClick={() => {
                                                                    if (!sessions?.id) {
                                                                        arrayHelpers.remove(index)
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Are you sure?',
                                                                            text: "You won't be able to revert this!",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            confirmButtonText: 'Yes, delete it!'
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                arrayHelpers.remove(index)
                                                                                dispatch(deleteLump({ id: sessions.id, type: active }))
                                                                            }
                                                                        })
                                                                    }
                                                                }} // remove a friend from the list
                                                            >
                                                                <i class="bi bi-trash"></i>
                                                            </button>
                                                        </div>
                                                        <div className='col-md-3 col-sm-12' >
                                                            {index == 0 ? <button
                                                                type="button"
                                                                className='btn btn-success p-2'
                                                                onClick={() => {
                                                                    arrayHelpers.insert(index, '')
                                                                }}
                                                            // onClick={() => } // insert an empty string at a position
                                                            >
                                                                Add New
                                                            </button> : null}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" className='btn btn-success' onClick={() => arrayHelpers.push('')}>
                                                    Add Session
                                                </button>
                                            )}
                                            {values.sessions.length ? <div>
                                                <button className='btn btn-primary mt-2' type="submit">Submit</button>
                                            </div> : null}
                                        </div>
                                    )}
                                />
                            </Form>
                        )}
                    />
                </div>
            </>
        )
    }

    const UploadDistricts = () => {
        return (
            <>
                <div className='col-md-12 bg-white rounded-2'>
                    <Formik
                        enableReinitialize
                        initialValues={{ districts: dataArray }}
                        onSubmit={values => dispatch(uploadLump({ data: values.districts, type: active }))}
                        render={({ values }) => (
                            <Form>
                                <FieldArray
                                    name="districts"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.districts && values.districts.length > 0 ? (
                                                values.districts.map((districts, index) => (
                                                    <div className='row align-items-end mt-2' key={index}>
                                                        <div className='col-md-6 col-sm-12' >
                                                            <Field className='form-control p-2' name={`districts.${index}.name`} />
                                                        </div>
                                                        <div className='col-md-1 col-sm-12' >
                                                            <button
                                                                type="button"
                                                                className='btn btn-danger p-2'
                                                                onClick={() => {
                                                                    if (!districts?.id) {
                                                                        arrayHelpers.remove(index)
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: 'Are you sure?',
                                                                            text: "You won't be able to revert this!",
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            confirmButtonText: 'Yes, delete it!'
                                                                        }).then((result) => {
                                                                            if (result.isConfirmed) {
                                                                                arrayHelpers.remove(index)
                                                                                dispatch(deleteLump({ id: districts.id, type: active }))
                                                                            }
                                                                        })
                                                                    }
                                                                }} // remove a friend from the list
                                                            >
                                                                <i class="bi bi-trash"></i>
                                                            </button>
                                                        </div>
                                                        <div className='col-md-3 col-sm-12' >
                                                            {index == 0 ? <button
                                                                type="button"
                                                                className='btn btn-success p-2'
                                                                onClick={() => {
                                                                    arrayHelpers.insert(index, '')
                                                                }}
                                                            // onClick={() => } // insert an empty string at a position
                                                            >
                                                                Add New
                                                            </button> : null}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <button type="button" className='btn btn-success' onClick={() => arrayHelpers.push('')}>
                                                    Add District
                                                </button>
                                            )}
                                            {values.districts.length ? <div>
                                                <button className='btn btn-primary mt-2' type="submit">Submit</button>
                                            </div> : null}
                                        </div>
                                    )}
                                />
                            </Form>
                        )}
                    />
                </div>
            </>
        )
    }

    const data = [{ name: 'Departments', data: UploadDepartments() }, { name: 'Sessions', data: UploadSessions() }, { name: 'State', data: UploadState() }, { name: 'District', data: UploadDistricts() }]

    return (
        <>
            <div className='container-fluid' tabindex="-1" role="dialog">
                {loading ? <div className='loader'>
                    <div className='loader_center'>
                        <Loader />
                    </div>
                </div> : null}
                <div className="mt-4" >
                    <div className="p-2 rounded-3">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            {data.map((item, i) => (
                                <a onClick={() => {
                                    setactive(item.name)
                                }} className={`nav-link p-3 mt-2 rounded-2 ${active == item.name && 'active'}`} id={item.name} data-bs-toggle="pill"
                                    data-bs-target={`#${item.name}`} type="button" role="tab"
                                    aria-controls={item.name} aria-selected="true">
                                    <div className="align-items-center p-0">
                                        <span style={{ fontSize: '12px ', marginLeft: '8px' }} className="fw-bold">{item.name}</span>
                                    </div>
                                </a>
                            ))}
                        </ul>
                    </div>
                    <div className="tab-content mt-2" id="v-pills-tabContent">
                        {data.map((item, i) => (
                            <div className={`tab-pane show ${active == item.name && 'active'}`} id={item.name} role="tabpanel"
                                aria-labelledby={item.name} tabindex="0">
                                <div className="card mainCard p-2">
                                    <div className="bg-white card-header fs-2">
                                        <div className="d-flex justify-content-between">
                                            <div className="fw-bold fs-5">
                                                {item.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        {item.data}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Lump