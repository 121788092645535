import { takeLatest, takeEvery, call, put, delay, select } from "redux-saga/effects"
import Api from '../../utils/Api'
import { setUsers, badResponse, setLoading } from "../reducers/users";
import Swal from 'sweetalert2';


function* fetchUsers() {
    try {
        const response = yield Api.get('/user')
        const res = response.data
        if (res.code == 1) {
            console.log(res.data)
            yield put(setUsers(res.data))
        } else {
            yield put(badResponse(res.message))
        }
    } catch (error) {
        console.log(error)
    }
}

function* approve(e) {
    yield put(setLoading(false))
    const params = {
        user_name: e?.payload?.username,
        user_password: e?.payload?.password,
        user_id: e?.payload?.user_id
    }
    try {
        const response = yield Api.post('/user/approve', params)
        const res = response.data
        if (res.code == 1) {
            console.log(res.data)
            Swal.fire(
                'Approved',
                res.message,
                'success'
            ).then(() => {
                window.location.reload()
            })
        } else {
            yield put(badResponse(res.message))
        }
    } catch (error) {
        console.log(error)
    }
}

function* userSaga() {
    yield takeLatest('users/fetchUsers', fetchUsers)
    yield takeLatest('users/approveUser', approve)
}

export default userSaga;