import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'circulars',
    initialState: {
        loading: null,
        error: null,
        popUp: null,
        message: '',
        circularData: [],
        deleteId: null
    },
    reducers: {
        showError: (circulars, action) => {
            circulars.error = action.payload;
        },
        showPopUp: (circulars, action) => {
            circulars.popUp = action.payload;
        },
        uploadCirculars: (circulars, action) => {
            circulars.loading = true;
        },
        circularUploaded: (circulars, action) => {
            circulars.popUp = 'uploaded';
            circulars.message = action.payload;
            circulars.loading = false;
        },
        uploadFailed: (circulars, action) => {
            circulars.error = action.payload;
            circulars.loading = false;
        },
        deletionFailed: (circulars, action) => {
            circulars.error = action.payload;
            circulars.loading = false;
        },
        fetchCirculars: (circulars, action) => {
            circulars.loading = true;
        },
        setCirculars: (circulars, action) => {
            circulars.circularData = action.payload;
            circulars.loading = false;
        },
        deleteCircular: (circulars, action) => {
            circulars.deleteId = action.payload;
            circulars.popUp = 'deleteConfirmation'
        },
        fetchDeleteCircular: (circulars, action) => {
            circulars.loading = true
        },
        circularDeleted: (circulars, action) => {
            circulars.popUp = 'deleted';
            circulars.loading = false
        },
    }
})


export const {
    showError,
    showPopUp,
    uploadCirculars,
    circularUploaded,
    uploadFailed,
    fetchCirculars,
    setCirculars,
    deleteCircular,
    fetchDeleteCircular,
    circularDeleted,
    deletionFailed
} = slice.actions

export default slice.reducer