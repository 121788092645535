import React, { useState, useEffect, useMemo } from 'react'
import DataTable, { defaultThemes } from 'react-data-table-component';
import AdminHeader from '../Components/AdminHeder';

const Table = ({ data, colms, needExport, title }) => {

    const [filterText, setFilterText] = useState('');

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = data.filter(
        item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    )



    const handleClear = () => {
        console.log('new data');
        if (filterText) {
            setFilterText('');
        }
    };

    const SearchBar = ({ filterText, onFilter, onClear, onExport }) => (
        <div className='row'>
            <div className='col-md-8'>
                <div className='row'>
                    <div className='col-md-10 p-0'>
                        <input
                            style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
                            autoFocus="autoFocus"
                            className='form-control'
                            placeholder='Search'
                            key='search'
                            type='text'
                            onChange={onFilter}
                            value={filterText}
                        />
                    </div>
                    <div className='col-md-2 p-0'>
                        <button style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} className='btn btn-dark' onClick={onClear}><span><i class="bi bi-arrow-clockwise"></i></span></button>
                    </div>
                </div>
            </div>
            {needExport ? <div className='col-md-4'>
                <button className='btn btn-danger' onClick={onExport}><span>Export</span></button>
            </div> : null}
        </div>
    )

    const subHeaderComponentMemo = () => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <SearchBar onExport={() => downloadCSV(data)} onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
    };

    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#e8eaed',
                minHeight: '56px',
                borderBottomWidth: '1px',
                borderBottomColor: defaultThemes.default.divider.default,
                borderBottomStyle: 'solid',
            },
        },
        cells: {
            style: {
                borderRightStyle: 'solid',
                borderLeftStyle: 'solid',
                borderRightWidth: '1px',
                borderLeftWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                borderLeftColor: defaultThemes.default.divider.default,
            },
        },
        header: {
            style: {
                backgroundColor: '#e8eaed',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px'
            },
        },
        subHeader: {
            style: {
                backgroundColor: '#e8eaed',
                minHeight: '52px',
            },
        },
    }

    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }


    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const Export = ({ onExport }) => (
        <div className='col-md-2'>
            <button className='btn btn-danger' onClick={onExport}><span>Export</span></button>
        </div>
    )

    const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);


    return (
        <>
            <DataTable
                columns={colms}
                striped
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeaderComponent={subHeaderComponentMemo()}
                persistTableHead
                customStyles={customStyles}
                title={title}
                actions={subHeaderComponentMemo()}
            />
        </>
    )
}

export default Table
