import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import AdminHeader from '../Components/AdminHeder';
import Table from '../Components/Table';
import { joinSchema, uploadCircSchema } from '../validations';
import Loader from '../Components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { uploadCirculars, showPopUp, fetchCirculars, deleteCircular, fetchDeleteCircular } from '../redux/reducers/circulars'
import { fetchLump } from '../redux/reducers/lump'

const CircularNotification = () => {

    const dispatch = useDispatch()

    const { error, popUp, loading, circularData } = useSelector(state => state.circulars)

    const { dataArray } = useSelector(state => state.lump)

    useEffect(() => {
        dispatch(fetchCirculars())
        dispatch(fetchLump('Departments'))
    }, [])

    const [tableData, settableData] = useState([
        {
            serial: 1,
            name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
            department: 'Health',
            date: '20-12-2011',
            uploadedDate: '10-12-2022',
        },
    ])

    const getDaprtment = (dep) => {
        const object = dataArray.find((i) => i.id == dep)
        return object?.name
    }

    useEffect(() => {
        const data = []
        circularData.map((item) => {
            data.push({
                name: item.circular_tittle, department: item.cicrular_department,
                date: item.circular_date, uploadDate: item.circular_upload_date, link: item.circular_link, id: item.circular_id
            })
        })
        settableData(data)
    }, [circularData])

    const columns = [
        {
            name: 'Tittle',
            selector: row => row.name,

        },
        {
            name: 'Department',
            selector: row => getDaprtment(row.department),
            width: "160px"
        },
        {
            name: 'Circular Date',
            selector: row => row.date,
            width: "160px"
        },
        {
            name: 'Download',
            selector: row => row.action,
            width: "160px",
            cell: (row) =>
                <div>
                    <a download href={row.link} style={{ fontSize: '12px', }} className='btn btn-success text-light' id={row.ppo} >Download</a >
                </div>
        },
    ];

    return (
        <>
            <div className='container' tabindex="-1" role="dialog">
                {loading ? <div className='loader'>
                    <div className='loader_center'>
                        <Loader />
                    </div>
                </div> : null}
                <div className='col-md-12 bg-white'>
                    <Table title={'ਨੋਟੀਫਿਕੇਸ਼ਨ ਅਤੇ ਸਰਕੂਲਰ'} data={tableData} colms={columns} />
                </div>
            </div>
        </>
    )
}

export default CircularNotification;