import React from 'react'
import { Link } from "react-router-dom";

const Index = () => {

    const data = [{
        image: 'https://picsum.photos/536/354', tittle: 'First News Letter',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy.Various versions have evolved over the years, sometimes by accident, sometimes on purpose(injected humour and the like'
    },
    {
        image: 'https://picsum.photos/536/354', tittle: 'First News Letter',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy.Various versions have evolved over the years, sometimes by accident, sometimes on purpose(injected humour and the like'
    },
    {
        image: 'https://picsum.photos/536/354', tittle: 'First News Letter',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy.Various versions have evolved over the years, sometimes by accident, sometimes on purpose(injected humour and the like'
    },
    {
        image: 'https://picsum.photos/536/354', tittle: 'First News Letter',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy.Various versions have evolved over the years, sometimes by accident, sometimes on purpose(injected humour and the like'
    },
    {
        image: 'https://picsum.photos/536/354', tittle: 'First News Letter',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy.Various versions have evolved over the years, sometimes by accident, sometimes on purpose(injected humour and the like'
    },
    {
        image: 'https://picsum.photos/536/354', tittle: 'First News Letter',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy.Various versions have evolved over the years, sometimes by accident, sometimes on purpose(injected humour and the like'
    },
    {
        image: 'https://picsum.photos/536/354', tittle: 'First News Letter',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy.Various versions have evolved over the years, sometimes by accident, sometimes on purpose(injected humour and the like'
    },
    {
        image: 'https://picsum.photos/536/354', tittle: 'First News Letter',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English.Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy.Various versions have evolved over the years, sometimes by accident, sometimes on purpose(injected humour and the like'
    }]

    return (
        <>
            <div className='container-fluid'>
                <div className='container'>
                    <div className='row mt-2 p-2 justify-content-center'>
                        {
                            data.map((item, index) => {
                                return (
                                    index > 5 ? null : <div class="col-lg-4 col-md-12 mb-4">
                                        <div class="card">
                                            <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                                <img src='' class="img-fluid" />
                                                <a href="#!">
                                                    <div class="mask" ></div>
                                                </a>
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">{item.tittle}</h5>
                                                <p style={{ height: '50px', overflow: 'hidden' }} class="card-text">

                                                </p>
                                                <a href="#!" class="btn btn-primary">Read</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <>
                        <section>
                            <div class="row d-flex justify-content-center">
                                <div class="col-md-10 col-xl-8 text-center">
                                    <h3 class="mb-4">ਸਾਡੇ ਬਾਰੇ</h3>
                                </div>
                            </div>
                            <div class="row justify-content-center text-center">
                                <div class="col-md-2 mb-5 mb-md-0">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="https://pwa.amandhiman.in/api/profile/team/petronone.png" class="rounded-circle shadow-1-strong"
                                            width="150" height="150" />
                                    </div>
                                    <h5 class="mb-3">ਛੱਜੂ ਰਾਮ ਜਿੰਦਲ</h5>
                                    <h6 class="text-info mb-3">ਸਰਪ੍ਰਸਤ</h6>
                                    <p class="px-xl-3">
                                        <i class="fas fa-quote-left pe-2"></i>ਦਿ ਪੈਨਸ਼ਨਰਜ਼ ਵੈਲਫੇਅਰ ਐਸੋਸੀਏਸ਼ਨ (ਰਜਿ.) ਸੁਨਾਮ ਦੇ ਸੰਸਥਾਪਕ ਛੱਜੂ ਰਾਮ ਜਿੰਦਲ ਪੈਟਰਨ, ਟਰੇਡ ਯੂਨੀਅਨ ਆਗੂ ਪੀਐਸਐਸਐਫ 1680 22-ਬੀ ਚੰਡੀਗੜ੍ਹ।
                                    </p>
                                </div>
                                <div class="col-md-2 mb-5 mb-md-0">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="https://pwa.amandhiman.in/api/profile/team/petrontwo.png" class="rounded-circle shadow-1-strong"
                                            width="150" height="150" />
                                    </div>
                                    <h5 class="mb-3">ਹੰਸ ਰਾਜ ਗਰਗ</h5>
                                    <h6 class="text-info mb-3">ਸਰਪ੍ਰਸਤ</h6>
                                    <p class="px-xl-3">
                                        <i class="fas fa-quote-left pe-2"></i>ਹੰਸ ਰਾਜ ਗਰਗ ਪੈਟਰਨ, ਦਿ ਪੈਨਸ਼ਨਰਜ਼ ਵੈਲਫੇਅਰ ਐਸੋਸੀਏਸ਼ਨ (ਰਜਿ.) ਸੁਨਾਮ ਦੇ ਸੰਸਥਾਪਕ, ਸੇਵਾਮੁਕਤ ਕਾਨੂੰਨਗੋ ਮਾਲ ਵਿਭਾਗ
                                    </p>
                                </div>
                                <div class="col-md-2 mb-5 mb-md-0">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="https://pwa.amandhiman.in/api/profile/team/President.png" class="rounded-circle shadow-1-strong"
                                            width="150" height="150" />
                                    </div>
                                    <h5 class="mb-3">ਭੁਪਿੰਦਰ ਸਿੰਘ ਛਾਜਲੀ</h5>
                                    <h6 class="text-info mb-3">ਪ੍ਰਧਾਨ</h6>
                                    <p class="px-xl-3">
                                        <i class="fas fa-quote-left pe-2"></i>ਭੁਪਿੰਦਰ ਸਿੰਘ ਛਾਜਲੀ ਪ੍ਰਧਾਨ ਸੇਵਾਮੁਕਤ ਲੈਕਚਰਾਰ ਜੀਵ ਵਿਗਿਆਨ ਸਰਕਾਰੀ ਸੀਨੀਅਰ ਸੈਕੰਡਰੀ ਸਕੂਲ (ਲੜਕੀਆਂ) ਸੁਨਾਮ।
                                    </p>
                                </div>
                                <div class="col-md-2 mb-5 mb-md-0">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="https://pwa.amandhiman.in/api/profile/team/GeneralSecretary.png"
                                            class="rounded-circle shadow-1-strong" width="150" height="150" />
                                    </div>
                                    <h5 class="mb-3">ਜੀਤ ਸਿੰਘ ਬੰਗਾ</h5>
                                    <h6 class="text-info mb-3">ਜਨਰਲ ਸਕੱਤਰ</h6>
                                    <p class="px-xl-3">
                                        <i class="fas fa-quote-left pe-2"></i>ਜੀਤ ਸਿੰਘ ਬੰਗਾ ਜਨਰਲ ਸਕੱਤਰ ਟਰੇਡ ਯੂਨੀਅਨ ਆਗੂ PSSF 1680 22-ਬੀ ਚੰਡੀਗੜ੍ਹ
                                    </p>
                                </div>
                                <div class="col-md-2 mb-0">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="https://pwa.amandhiman.in/api/profile/team/FinanceSecretary.jpeg"
                                            class="rounded-circle shadow-1-strong" width="150" height="150" />
                                    </div>
                                    <h5 class="mb-3">ਪਵਨ ਕੁਮਾਰ ਜਿੰਦਲ</h5>
                                    <h6 class="text-info mb-3">ਵਿੱਤ ਸਕੱਤਰ</h6>
                                    <p class="px-xl-3">
                                        <i class="fas fa-quote-left pe-2"></i>ਪਵਨ ਕੁਮਾਰ ਜਿੰਦਲ ਵਿੱਤ ਸਕੱਤਰ ਰਿਟਾਇਰਡ ਫਾਰਮੇਸੀ ਅਫਸਰ ਸਿਵਲ ਹਸਪਤਾਲ ਸੁਨਾਮ ਟਰੇਡ ਯੂਨੀਅਨ ਆਗੂ
                                    </p>
                                </div>
                            </div>
                        </section>
                    </>
                </div>
            </div>
        </>
    )
}

export default Index
