import { fork, all } from 'redux-saga/effects'
import authSaga from './authSaga'
import circularsSaga from './circularsSaga'
import joinSaga from './joinSaga'
import lumpSaga from './lumpSaga'
import userSaga from './userSaga'


export default function* rootSaga() {
    yield all([
        authSaga(),
        circularsSaga(),
        joinSaga(),
        userSaga(),
        lumpSaga()
    ])
}