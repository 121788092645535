import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
    name: 'lump',
    initialState: {
        loading: null,
        popUp: null,
        error: null,
        department_id: null,
        dataArray: [],
    },
    reducers: {
        showError: (lump, action) => {
            lump.error = action.payload;
            lump.loading = false;
        },
        showPopUp: (lump, action) => {
            lump.popUp = action.payload;
            lump.loading = false;
        },
        showLoading: (lump, action) => {
            lump.loading = action.payload;
        },
        fetchLump: (lump, action) => {
            lump.loading = true;
        },
        setFetchedData: (lump, action) => {
            const { data, type } = action.payload;
            const array = []
            data.map((item) => {
                if (type == 'Departments') {
                    array.push({
                        name: item.department_name,
                        id: item.department_id,
                    })
                } else if (type == 'Sessions') {
                    array.push({
                        name: item.session_name,
                        id: item.session_id,
                    })
                } else if (type == 'State') {
                    array.push({
                        name: item.state_name,
                        id: item.state_id,
                    })
                } else {
                    array.push({
                        name: item.district_name,
                        id: item.district_id,
                    })
                }
            })
            lump.dataArray = array
            lump.loading = false;
        },
        uploadLump: (lump, action) => {
            lump.loading = true;
        },
        deleteLump: (lump, action) => {
            console.log(action.payload, 'delete department')
        },
    }
})


export const {
    showError,
    showPopUp,
    showLoading,
    uploadLump,
    deleteLump,
    setFetchedData,
    fetchLump,
} = slice.actions

export default slice.reducer