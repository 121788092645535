import { takeLatest, takeEvery, call, put, delay, select } from "redux-saga/effects"
import Api from '../../utils/Api'
import { setFetchedData, showError, showLoading } from "../reducers/lump"
import Swal from 'sweetalert2';




function* fetchLump(payload) {
    const type = payload.payload
    const params = {
        lump_type: type
    }
    try {
        const response = yield Api.post('/lump/', params)
        const res = response.data
        console.log(res)
        if (res.code == 1) {
            yield put(setFetchedData({ data: res.data, type: type }))
        } else {
            yield put(showError(res.message))
        }
    } catch (error) {
        console.log(error)
    }
}


function* uploadLump(payload) {
    const data = payload?.payload?.data
    const type = payload?.payload?.type
    const newDepartments = yield call(filterData, data)
    if (newDepartments.length > 0) {
        const params = {
            lump_type: type,
            pwa_departments: newDepartments
        }
        try {
            const response = yield Api.post('/lump/uploadDepartments', params)
            const res = response.data
            yield put(showLoading(false))
            if (res.code == 1) {
                Swal.fire(
                    'Added',
                    res.message,
                    'success'
                ).then(() => {
                    window.location.reload()
                })
            } else {
                yield put(showError(res.message))
            }
        } catch (error) {
            yield put(showLoading(false))
            Swal.fire(
                'Error',
                error.toString(),
                'error'
            )
        }
    } else {
        yield put(showLoading(false))
        Swal.fire(
            'Nothing Added',
            '',
            'info'
        )
    }
}

function* deleteLump(payload) {
    const id = payload?.payload?.id
    const type = payload?.payload?.type
    console.log(id, type)
    const params = {
        lump_type: type,
        departement_id: id
    }
    try {
        const response = yield Api.post('/lump/deleteDepartment', params)
        const res = response.data
        if (res.code == 1) {
            yield put(showLoading(false))
            Swal.fire(
                'Deleted',
                res.message,
                'success'
            ).then(() => {
                window.location.reload()
            })
        } else {
            yield put(showError(res.message))
        }
    } catch (error) {
        yield put(showLoading(false))
        Swal.fire(
            'Error',
            error.toString(),
            'error'
        )
    }
}

function* filterData(payload) {
    const departments = yield select(state => state.lump.dataArray)
    const array3 = payload.filter(function (obj) { return departments.indexOf(obj) == -1; });
    return array3
}

function* lumpSaga() {
    yield takeLatest('lump/fetchLump', fetchLump)
    yield takeLatest('lump/uploadLump', uploadLump)
    yield takeLatest('lump/deleteLump', deleteLump)
}

export default lumpSaga;







