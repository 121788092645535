import * as yup from 'yup';

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]

const joinSchema = yup.object().shape({
    fname: yup.string().required('Please Enter First Name'),
    mname: yup.string().notRequired(),
    lname: yup.string().required('Please Enter Last Name'),
    mobile: yup.number().required('Mobile Number is Required').min(10),
    email: yup.string().email().required('Please Enter Email'),
    ppo: yup.mixed().required('PPO Number is Mandatory'),
    department: yup.string().required('Please Select Department'),
    basic_pension: yup.string().notRequired(),
    pensionType: yup.number().required('Please Select Pension Type'),
    dob: yup.string().notRequired(),
    dod: yup.string().notRequired(),
    address: yup.string().notRequired(),
    dor: yup.string().required('Please Enter Retirement Date'),
    doc: yup.string().required('Please Enter Commencement Date'),
    state: yup.string().required('Please Select Date'),
    district: yup.string().required('Please Select District'),
    pincode: yup.number().required('Please Enter Pincode').min(6),
    reg_date: yup.string().required(),
    session: yup.string().required('Please Select Session'),
    amount: yup.number().required('Please Enter Amount'),
    pic: yup.mixed().required('Please Upload Image')
});


const uploadCircSchema = yup.object().shape({
    name: yup.string().required('Please Enter Circular Tittle'),
    department: yup.string().required('Please Select Department'),
    date: yup.date().required('Please Select Circular Date'),
    file: yup.mixed().test('fileFormat', 'Please Upload Pdf File Only', (value) => {
        console.log(value); return value && ['application/pdf'].includes(value.type);
    }),
})

const PasswordSchema = yup.object().shape({
    password: yup.string().required('Please Enter Password'),
})


export { joinSchema, uploadCircSchema, PasswordSchema }