import React from 'react'
import { Link, Outlet, useLocation } from "react-router-dom";

const OutletMain = () => {

    const active = useLocation();

    return (
        <>
            <div className='container-fluid'>
                <>
                    <nav class="navbar navbar-expand-lg headerBg">
                        <div class="container">
                            <a>
                                <img src="/media/pwaLogo.png" width="120" height="120" />
                            </a>
                            <button class="navbar-toggler  bg-light" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon">
                                    <span class="navbar-toggler-icon">
                                    </span>
                                </span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav nav-pills ms-auto">
                                    {active.pathname !== '/*' ? <li class="nav-item">
                                        <Link to={'/'} className='nav-link headerLinks'><i class="bi bi-house-fill"></i>Home</Link>
                                    </li> : null}
                                    {active.pathname !== '/join' ? <li class="nav-item">
                                        <Link to={'/join'} className='nav-link headerLinks'>ਸਾਡੇ ਨਾਲ ਜੁੜੋ</Link>
                                    </li> : null}
                                    {active.pathname !== '/circulars' ? <li class="nav-item">
                                        <Link to={'/circulars'} className='nav-link headerLinks'>ਨੋਟੀਫਿਕੇਸ਼ਨ ਅਤੇ ਸਰਕੂਲਰ</Link>
                                    </li> : null}
                                </ul>
                            </div>
                        </div>
                    </nav>
                </>
                <div className='container mt-4'>
                    <Outlet />
                </div>
                <>
                    <div class="container-fluid headerBg mt-5">
                        <footer class="footer text-center text-lg-start text-white">
                            <div class="container p-4 pb-0">
                                <section class="">
                                    <div class="row">
                                        <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                                            <h6 class="text-uppercase mb-4 font-weight-bold">
                                                ਪੈਨਸ਼ਨਰਜ਼ ਵੈਲਫੇਅਰ ਐਸੋਸੀਏਸ਼ਨ ਰਜਿ. ਸੁਨਾਮ
                                            </h6>
                                            <p>
                                                <Link to={'/login'} className="text-white text-decoration-none"><i class="bi bi-diagram-3-fill"></i>&nbsp; ਪੈਨਸ਼ਨਰ ਲਾਗਿਨ</Link>
                                            </p>
                                            <div>
                                                © 2022 Copyright:
                                                <Link to={'/'} className='text-white'>PwaSunam.in</Link>
                                            </div>
                                        </div>
                                        <hr class="w-100 clearfix d-md-none" />
                                        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                                            <h6 class="text-uppercase mb-4 font-weight-bold">
                                                ਉਪਯੋਗੀ ਲਿੰਕ
                                            </h6>
                                            <p>
                                                <Link to={'/'} className='text-white text-decoration-none'>ਤੁਹਾਡਾ ਖਾਤਾ</Link>
                                            </p>
                                            <p>
                                                <Link to={'/join'} className='text-white text-decoration-none'>ਸਾਡੇ ਨਾਲ ਜੁੜੋ</Link>
                                            </p>
                                            <p>
                                                <Link to={'/circulars'} className='text-white text-decoration-none'>ਨੋਟੀਫਿਕੇਸ਼ਨ ਅਤੇ ਸਰਕੂਲਰ</Link>
                                            </p>
                                        </div>
                                        <hr class="w-100 clearfix d-md-none" />
                                        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                                            <h6 class="text-uppercase mb-4 font-weight-bold">ਸੰਪਰਕ ਕਰੋ</h6>
                                            <p><i class="fas fa-home mr-3"></i>ਪੈਨਸ਼ਨ ਵੈਲਫੇਅਰ ਐਸੋਸੀਏਸ਼ਨ, ਪੈਨਸ਼ਨ ਭਵਨ, ਸੁਨਾਮ (148028)</p>
                                            <p><i class="fas fa-envelope mr-3"></i>pwasunam2017@gmail.com</p>
                                            <p><i class="fas fa-print mr-3"></i>+91 94174-16064</p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </footer>
                    </div>
                </>
            </div>
        </>
    )
}

export default OutletMain
