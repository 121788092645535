import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserCard from '../Components/UserCard'
import { fetchdssd } from '../redux/reducers/joining';

const Profile = () => {


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchdssd())
    }, [])

    const { districts, states, departments, sessions } = useSelector(state => state.joining)

    const { user } = useSelector(state => state.auth)

    const getDapartment = (dep) => {
        const object = departments.find((i) => i.department_id == dep)
        return object?.department_name
    }

    const getSession = (session) => {
        const object = sessions.find((i) => i.session_id == session)
        return object?.session_name
    }

    const getPensionType = (type) => {
        if (type == 0) {
            return 'Pension'
        } else {
            return 'Family Pension'
        }
    }

    console.log(user)

    return (
        <>
            <div className='container'>
                <div class="card mt-4">
                    <div class="p-4 card-header">
                        <div className='d-flex align-items-center'>
                            <div className='col-md-4 col-sm-12 '>
                                <img src={user.user_image} style={{ height: 120, width: 120, borderRadius: 60 }} />
                            </div>
                            <div className='col-md-8 col-sm-12 align-items-center'>
                                <h6><i class="bi bi-person-fill fs-5 me-1"></i>{user.user_first_name + ' ' + user.user_middle_name + ' ' + user.user_last_name}</h6>
                                <h6><i class="bi bi-phone-fill fs-5 me-1"></i>{user?.user_mobile}</h6>
                                <h6><i class="bi bi-postcard-fill fs-5 me-1"></i>{user?.user_ppo_number}</h6>
                                <h6><i class="bi bi-geo-fill fs-5 me-1"></i>{user?.user_address ? user?.user_address : 'N/A'}</h6>
                                <h6><i class="bi bi-envelope-fill fs-5 me-1"></i>{user?.user_email ? user?.user_email : 'N/A'}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div className='row'>
                            <div className='col'>
                                <h6 className='fw-bold' >Date of Birth</h6>
                                <p class="fs-6">{user.user_birth_date}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Cmmnc. Date</h6>
                                <p class="fs-6">{user.user_cmmnce_date}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Form Date</h6>
                                <p class="fs-6">{user.user_created_date}</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='fw-bold'>Death Date</h6>
                                <p class="fs-6">{user.user_death_date ? user.user_death_date : 'N/A'}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Department</h6>
                                <p class="fs-6">{getDapartment(user.user_department) ? getDapartment(user.user_department) : 'N/A'}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Pension Type</h6>
                                <p class="fs-6">{getPensionType(user.pension_type) ? getPensionType(user.pension_type) : 'N /A'}</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='fw-bold'>Date of Retirement</h6>
                                <p class="fs-6">{user?.user_retd_date ? user?.user_retd_date : 'N/A'}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Pension</h6>
                                <p class="fs-6">₹{user.user_pension ? user.user_pension : 'N/A'}</p>
                            </div>
                            <div className='col'>
                                <h6 className='fw-bold'>Registration Amount</h6>
                                <p class="fs-6">₹{user?.user_amount ? user?.user_amount : 'N/A'}</p>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='row'>
                            <div className='col'>
                                <h6 className='fw-bold'>Session</h6>
                                <p class="fs-6">{getSession(user.user_session) ? getSession(user.user_session) : 'N/A'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
