import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import AdminHeader from '../Components/AdminHeder';
import Table from '../Components/Table';
import { joinSchema, uploadCircSchema } from '../validations';
import Loader from '../Components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { uploadnews, showPopUp, fetchnews, deletenews, fetchDeletenews } from '../redux/reducers/news'
import moment from 'moment';
import Confirmation from '../Components/Confirmation';

const News = () => {

    const dispatch = useDispatch()

    const [active, setactive] = useState(localStorage.getItem('SelectedOptionNews') || 'News')

    const { error, popUp, loading, circularData } = useSelector(state => state.circulars)

    useEffect(() => {
        dispatch(fetchnews())
    }, [])

    const upload = () => {
        document.getElementById("pic").click()
    }

    const setSelectedOption = (option) => {
        localStorage.setItem('SelectedOptionNews', option);
    }

    const [tableData, settableData] = useState([
        {
            serial: 1,
            name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s',
            department: 'Health',
            date: '20-12-2011',
            uploadedDate: '10-12-2022',
        },
    ])


    useEffect(() => {
        const data = []
        circularData.map((item) => {
            data.push({
                name: item.circular_tittle, department: item.cicrular_department,
                date: item.circular_date, uploadDate: item.circular_upload_date, link: item.circular_link, id: item.circular_id
            })
        })
        settableData(data)
    }, [circularData])

    const columns = [
        {
            name: 'Tittle',
            selector: row => row.name,

        },
        {
            name: 'Department',
            selector: row => row.department,
            width: "160px"
        },
        {
            name: 'Circular Date',
            selector: row => row.date,
            width: "160px"
        },
        {
            name: 'Uploaded Date',
            selector: row => row.uploadDate,
            width: "160px"
        },
        {
            name: 'Action',
            selector: row => row.action,
            width: "160px",
            cell: (row) =>
                <div>
                    <a download href={'http://localhost:3100/public/' + row.name + '.pdf'} style={{ fontSize: '12px', }} className='btn text-success' id={row.ppo} ><i class="fs-5 bi bi-download"></i></a >
                    <button style={{ fontSize: '12px', marginLeft: 16 }} className='btn  text-danger' onClick={(e) => dispatch(deletenews(row.id))} id={row.id} ><i class="fs-5 bi bi-trash"></i></button >
                </div>
        },
    ];

    const UploadNews = () => {
        return (
            <>
                <div className='col-md-12 bg-white rounded-2'>
                    <Formik
                        initialValues={{ name: '', department: '', date: '', pic: '', file: '', uploadDate: moment(new Date()).format('DD-MM-YYYY') }}
                        onSubmit={(values) => { dispatch(uploadnews(values)) }}
                        validationSchema={uploadCircSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                            /* and other goodies */
                        }) => (
                            <form className='mt-4' onSubmit={handleSubmit}>
                                {error ? <div class="alert alert-danger" role="alert">
                                    {error}
                                </div> : null}
                                <div>
                                    <label className='form-label' for='name'>News Image</label>
                                    <a onClick={() => upload()} class="col-md-12 card d-flex">
                                        <img className='uploadNewsImage' src={values.pic != '' ? URL.createObjectURL(values.pic) : '/media/placeholder.png'} />
                                        <div>
                                            <input onChange={(event) => { setFieldValue("pic", event.currentTarget.files[0]) }} type="file" hidden class="form-control py-2" name="pic" id="pic" placeholder="" />
                                        </div>
                                    </a>
                                    <span className='text-danger errorInput'>{errors.file && touched.file && errors.file}</span>
                                </div>
                                <div>
                                    <label className='form-label mt-3' for='name'>News Tittle</label>
                                    <input onChange={handleChange} value={values.name} id='name' type={'text'} name='name' className='form-control p-3' />
                                    <span className='text-danger errorInput'>{errors.name && touched.name && errors.name}</span>
                                </div>
                                <div className='mt-3'>
                                    <label for="floatingTextarea">News Description</label>
                                    <div class="form-floating">
                                        <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
                                    </div>
                                </div>
                                <div className='col-md-12 col-sm-12 mt-2'>
                                    <label className='form-label' for='date'>News Date</label>
                                    <input onChange={handleChange} value={values.date} id='date' type={'date'} name='date' className='form-control p-3' />
                                    <span className='text-danger errorInput'>{errors.date && touched.date && errors.date}</span>
                                </div>
                                <div className='mt-3'>
                                    <div className='d-flex justify-content-center'>
                                        <button className='btn btn-success mt-4 col-md-3 align-center p-3' type="submit">
                                            {loading ? <Loader /> : <span className='text-white'>Submit</span>}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            </>
        )
    }


    const News = () => {
        return (
            <>
                <div className='col-md-12 bg-white'>
                    <Table data={tableData} colms={columns} />
                </div>
            </>
        )
    }


    const data = [{ name: 'News', data: News() }, { name: 'Upload News', data: UploadNews() }]

    return (
        <>
            <AdminHeader active={'News'} />
            <div className='container-fluid' tabindex="-1" role="dialog">
                {loading ? <div className='loader'>
                    <div className='loader_center'>
                        <Loader />
                    </div>
                </div> : null}
                <Confirmation
                    display={popUp === 'uploaded' ? 'block' : 'none'}
                    heading={'Circular Uploaded'}
                    description={'You Can Download/View Circular in Circulars Tab'}
                    button2={'OK'}
                    button2Color={'btn-success'}
                    button2Click={() => {
                        dispatch(showPopUp(null))
                        window.location.reload()
                    }}
                />
                <Confirmation
                    display={popUp === 'deleted' ? 'block' : 'none'}
                    heading={'Circular Deleted'}
                    description={'Circular Deleted as per your Request'}
                    button2={'OK'}
                    button2Color={'btn-success'}
                    button2Click={() => {
                        dispatch(showPopUp(null))
                        window.location.reload()
                    }}
                />
                <Confirmation
                    display={popUp === 'deleteConfirmation' ? 'block' : 'none'}
                    heading={'Confirmation'}
                    description={'Are You Sure You want to delete'}
                    button1={'Cancel'}
                    button2={'Delete'}
                    button2Color={'btn-danger'}
                    button1Click={() => { dispatch(showPopUp(null)) }}
                    button2Click={() => { dispatch(fetchDeletenews()) }}
                />
                <div className="row mt-4" >
                    <div className="col-md-3">
                        <div className="p-2 mainCard card rounded-3">
                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                <div className="fw-bold fs-3 p-2">News</div>
                                {data.map((item, i) => (
                                    <a onClick={() => {
                                        setactive(item.name)
                                        setSelectedOption(item.name)
                                    }} className={`nav-link p-3 mt-2 rounded-2 ${active === item.name && 'active'}`} id={item.name} data-bs-toggle="pill"
                                        data-bs-target={`#${item.name}`} type="button" role="tab"
                                        aria-controls={item.name} aria-selected="true">
                                        <div className="align-items-center p-0">
                                            <span style={{ fontSize: '12px ', marginLeft: '8px' }} className="fw-bold">{item.name}</span>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            {data.map((item, i) => (
                                <div className={`tab-pane show ${active === item.name && 'active'}`} id={item.name} role="tabpanel"
                                    aria-labelledby={item.name} tabindex="0">
                                    <div className="card mainCard p-2">
                                        <div className="bg-white card-header fs-2">
                                            <div className="d-flex justify-content-between">
                                                <div className="fw-bold fs-3">
                                                    {item.name}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            {item.data}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default News;